import Button from '@/components/ui/buttons/Button/Button'
import { Dialog } from '@/components/ui/Dialog/Dialog'
import { ImageAreaSelection } from '@/components/ui/fields/ImageAreaSelection/ImageAreaSelection'
import { Loadable } from '@/components/ui/Loadable/Loadable'
import { AVATAR_CROPPED_SIZE } from '@/config/ui'
import { uploadingService } from '@/store/uploadings/uploading.service'
import { getCroppedImageBlob } from '@/utils/image-crop'
import { DialogActions, DialogTitle } from '@mui/material'
import { toastWarning } from '@roolz/sdk/components/snackbars'
import { uploadFileResponse } from '@roolz/types/api/files'
import { Coordinates, Mimetypes } from '@roolz/types/custom'
import * as React from 'react'
import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './UploadAvatar.module.scss'

interface Props {
  file?: File
  open: boolean
  setOpen: (open: boolean) => any
  onLoaded: (params: uploadFileResponse) => void
  shape?: 'circle' | 'rectangle'
}

export const UploadAvatar = ({ file, open, setOpen, onLoaded, shape = 'circle' }: Props) => {
  const { t } = useTranslation('modals')
  const { t: errorsT } = useTranslation('errors')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const scaleRef = useRef<number>(1)
  const positionRef = useRef<Coordinates>({ x: 0, y: 0 })
  const imageRef = useRef<any>()

  const handleScale = useCallback((value: number) => scaleRef.current = value, [])
  const handleDrag = useCallback((value: Coordinates) => positionRef.current = value, [])
  const handleImageLoaded = useCallback((file: any) => imageRef.current = file, [])
  const handleCrop = useCallback(async () => {
    if(!imageRef.current) {
      return
    }

    try {
      setIsLoading(true)

      const blob = await getCroppedImageBlob({
        image: imageRef.current,
        offsetX: positionRef.current.x,
        offsetY: positionRef.current.y,
        width: 180,
        height: 180,
        scale: scaleRef.current,
        quality: AVATAR_CROPPED_SIZE / 180
      }, Mimetypes.PNG)

      if(!blob) {
        throw new Error()
      }

      const uploadingResult = await uploadingService.uploadFileByMultipart({
        file_size: blob?.size,
        origin_name: file?.name ?? 'avatar.png',
        file_mimetype: Mimetypes.PNG,
        file_type: 'image',
        is_avatar: true,
        file: blob
      })

      onLoaded(uploadingResult)
      setOpen(false)
    } catch(e) {
      console.log(e)
      toastWarning(errorsT('insufficient_request'))
    } finally {
      setIsLoading(false)
    }
  }, [])

  return (
    <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{ borderRadius: 0 }}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          {t('upload_avatar.title')}
        </DialogTitle>
        <div className={style.cropper}>
          <ImageAreaSelection
            file={file}
            onScale={handleScale}
            onDrag={handleDrag}
            onImageLoaded={handleImageLoaded}
            shape={shape}
            selectionWidth={180}
            selectionHeight={180}
          />
        </div>
        <DialogActions>
          <Button
            variant='text'
            color='muted'
            onClick={() => setOpen(false)}
            sx={{ color: '#898E93' }}
          >
            {t('upload_avatar.cancel')}
          </Button>
          <Loadable loading={isLoading}>
            <Button
              variant='text'
              onClick={handleCrop}
              autoFocus
              disabled={isLoading}
            >
              {t('upload_avatar.save')}
            </Button>
          </Loadable>
        </DialogActions>
      </Dialog>
  )
}
