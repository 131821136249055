import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { Company, CompanyInternalInfo } from '@roolz/types/api/companies'
import { mergeWith } from 'lodash-es'
import { makeAutoObservable } from 'mobx'

class CompaniesStore {
  companies: Company[] = []
  myCompany: Company | null = null
  isMyCompanyLoading = false

  get myCompanyIds() {
    return this.companies.map(item => item.id)
  }

  constructor() {
    makeAutoObservable(this)
  }

  get activeCompany(): Company | null {
    const activeSpaceId = profilesStore.my_profile?.active_space_company_id

    return myCompaniesStore.companies.find(company => company.id === activeSpaceId) || null
  }

  addOrUpdateCompany = (company: Company) => {
    const existing = this.companies.find(({ id }) => id === company?.id)

    if(existing) {
      return mergeWith(existing, company, (a: unknown, b: unknown) => {
        if(Array.isArray(b)) return b
      })
    }

    this.companies.push(company)
  }

  findCompany = (id: Company['id']): Company | undefined => {
    return this.companies.find(company => company.id === id)
  }

  resetState() {
    this.companies = []
  }
}

const companiesStore = new CompaniesStore()

export {
  CompaniesStore,
  companiesStore
}
