import { persistAndSyncStore } from '@/utils/mobx'
import {
  CompanyAssociation,
  CompanyAssociationSlug,
  CompanyCargoType,
  CompanyCargoTypeSlug,
  CompanyEmployeeAmount,
  CompanyEmployeeAmountSlug,
  CompanyForm,
  CompanyFormSlug,
  CompanyGoal,
  CompanyGoalSlug,
  CustomsService,
  CustomsServiceSlug,
  ExchangeRole,
  ExchangeRoleSlug,
  FleetAmount,
  FleetAmountSlug,
  LogisticsService,
  LogisticsServiceSlug,
  OrderAmount,
  OrderAmountSlug,
  TransportationType,
  TransportationTypeSlug
} from '@roolz/types/api/knowledge/companies'
import { sortByIndex } from '@roolz/sdk/utils/arrays'
import { makeAutoObservable } from 'mobx'

class CompanyKnowledgeStore {
  // is all options are loaded and ready to be used
  isReady = false
  allCompanyAssociations: CompanyAssociation[] = []
  allCompanyGoals: CompanyGoal[] = []
  allCompanyCargoTypes: CompanyCargoType[] = []
  allCompanyForms: CompanyForm[] = []
  allCustomsServices: CustomsService[] = []
  allExchangeRoles: ExchangeRole[] = []
  allCompanyEmployeeAmounts: CompanyEmployeeAmount[] = []
  allFleetAmounts: FleetAmount[] = []
  allLogisticsServices: LogisticsService[] = []
  allOrderAmounts: OrderAmount[] = []
  allTransportationTypes: TransportationType[] = []
  constructor() {
    makeAutoObservable(this)
  }
  get supportedCompanyAssociations(): CompanyAssociation[] {
    return sortByIndex(this.allCompanyAssociations.filter(({ slug }) => Object.values(CompanyAssociationSlug)
      .includes(slug)))
  }
  get supportedCompanyGoals(): CompanyGoal[] {
    return sortByIndex(this.allCompanyGoals.filter(({ slug }) => Object.values(CompanyGoalSlug).includes(slug)))
  }
  get supportedCompanyCargoTypes(): CompanyCargoType[] {
    return sortByIndex(this.allCompanyCargoTypes.filter(({ slug }) => Object.values(CompanyCargoTypeSlug)
      .includes(slug)))
  }
  get supportedCompanyForms(): CompanyForm[] {
    return sortByIndex(this.allCompanyForms.filter(({ slug }) => Object.values(CompanyFormSlug).includes(slug)))
  }
  get supportedCustomsServices(): CustomsService[] {
    return sortByIndex(this.allCustomsServices.filter(({ slug }) => Object.values(CustomsServiceSlug).includes(slug)))
  }
  get supportedExchangeRoles(): ExchangeRole[] {
    return sortByIndex(this.allExchangeRoles.filter(({ slug }) => Object.values(ExchangeRoleSlug).includes(slug)))
  }
  get supportedCompanyEmployeeAmounts(): CompanyEmployeeAmount[] {
    return sortByIndex(this.allCompanyEmployeeAmounts.filter(({ slug }) => Object.values(CompanyEmployeeAmountSlug)
      .includes(slug)))
  }
  get supportedFleetAmounts(): FleetAmount[] {
    return sortByIndex(this.allFleetAmounts.filter(({ slug }) => Object.values(FleetAmountSlug).includes(slug)))
  }
  get supportedLogisticsServices(): LogisticsService[] {
    return sortByIndex(this.allLogisticsServices.filter(({ slug }) => Object.values(LogisticsServiceSlug)
      .includes(slug)))
  }
  get supportedOrderAmounts(): OrderAmount[] {
    return sortByIndex(this.allOrderAmounts.filter(({ slug }) => Object.values(OrderAmountSlug).includes(slug)))
  }
  get supportedTransportationTypes(): TransportationType[] {
    return sortByIndex(this.allTransportationTypes.filter(({ slug }) => Object.values(TransportationTypeSlug)
      .includes(slug)))
  }

  reset() {
    const instance = new CompanyKnowledgeStore()
    // @ts-ignore
    for(const [key, value] in Object.entries(instance)) {
      // @ts-ignore
      this[key] = value
    }
  }
}
const companyKnowledgeStore = persistAndSyncStore(new CompanyKnowledgeStore(), 'companyKnowledge')

export {
  CompanyKnowledgeStore,
  companyKnowledgeStore
}
