import { ApiClients } from '@roolz/api/clients'
import { AxiosInstance } from 'axios'

export type SortingValue = 'asc' | 'desc'

export type Settings = {
  order_by?: {
    key: string
    value: SortingValue
    // [T: string]: SortingValue
  },
  settings: Record<string, { value: boolean, index: number }>
}

export type SettingsResponse = {
  profile_list_config: Settings | null
  company_list_config: Settings | null
}

type StringifiedSettings = {
  profile_list_config: string | null
  company_list_config: string | null
}

const getParsedData = (data: StringifiedSettings) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    // @ts-ignore
    acc[key] = value !== null ? JSON.parse(value) : null
    return acc
  }, {})
}

export class AdminApi {
  admin: AxiosInstance

  constructor(clients: ApiClients) {
    this.admin = clients.admin
  }

  async getSettings() {
    const response = await this.admin.get<StringifiedSettings>('v1/config')
    const parsedData = getParsedData(response.data)

    return {
      ...response,
      data: parsedData as SettingsResponse
    }
  }

  async saveSettings(body: Partial<SettingsResponse>) {
    const stringifiedBody = Object.entries(body).reduce((acc, [key, value]) => {
      // @ts-ignore
      acc[key] = JSON.stringify(value)
      return acc
    }, {})

    const response = await this.admin.patch<StringifiedSettings>('v1/config', stringifiedBody)

    const parsedData = getParsedData(response.data)

    return {
      ...response,
      data: parsedData as SettingsResponse
    }
  }
}